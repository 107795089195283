import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SectionTitle from "../components/sectionTitle"
import Section from "../components/section"
import CongesContent from "../components/collaboration/congesContent"
import GestionTempsContent from "../components/collaboration/gestionTempsContent"
import AbsencesContent from "../components/collaboration/absencesContent"

import collaborationIcon from "../images/icon-collaboration.png"
import CongesImage from "../images/demande-conges.jpg"
import AbsencesImage from "../images/justif-absence.jpg"
import SocialButtons from "../components/socialButtons"
import adpImage from "../images/consultant-suivi.jpg"

const CollaborationPage = () => (
  <Layout>
    <SocialButtons />
    <SEO title="Cleeven s'engage" />
    <div id="collaboration-content" className="row">
      <Hero id="collaboration" text="Informations RH" />
      <SectionTitle
        text="Notre Collaboration"
        left
        iconImg={collaborationIcon}
      />
      <Section
        title="Règles de demande de congés"
        inversed
        imageUrl={CongesImage}
        bgColor="#f3f3f3"
      >
        <CongesContent />
      </Section>
      <Section
        title="Gestion des temps"
        bgColor="#ffffff"
        imageUrl={adpImage}
      >
        <GestionTempsContent />
      </Section>
      <Section
        title="Justification des absences"
        inversed
        whiteText
        imageUrl={AbsencesImage}
        bgColor="#408bc7"
      >
        <AbsencesContent />
      </Section>
    </div>
  </Layout>
)

export default CollaborationPage

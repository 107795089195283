import React from "react"

const GestionTempsContent = () => (
  <div className="p-5">
    <h3 className="font-weight-bold text-black pb-4">
      Cleeven utilise un SIRH (Système d’information RH) afin de permettre d’optimiser la performance de nos processus RH.
    </h3>
    <p>
      Cet outil nous permet non seulement l’automatisation de la production de la paie mais aussi la digitalisation de certains processus. La gestion des temps est notamment entièrement dématérialisée avec la saisie sur une application Web et l’import directement en paie. Vous aurez la possibilité de consulter vos compteurs de congés et d’adresser vos demandes de congés via cette interface.
    </p>
  </div>
)

export default GestionTempsContent
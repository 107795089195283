import React from "react"

const AbsencesContent = () => (
  <div className="p-5">
    <h3 className="font-weight-bold pb-4">
      En cas d’absence pour maladie, votre arrêt de travail doit parvenir chez Cleeven dans les 48 heures qui suivent votre absence, quelle que soit la durée de votre arrêt.
    </h3>
    <p>
      Prévenez votre manager ou le service administratif dans les meilleurs délais (dans les 24h, sauf cas de force majeure). Votre manager préviendra le client.
    </p>
    <p>
      De même en cas de retard ou d’absence pour toute autre raison, nous vous demandons de prévenir votre manager dans les meilleurs délais.
    </p>
  </div>
)

export default AbsencesContent
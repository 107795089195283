import React from "react"

const CongesContent = () => (
  <div className="p-5">
    <h3 className="font-weight-bold text-black pb-4">
      Toutes les demandes de congés doivent obligatoirement être validées en premier lieu par votre Manager Cleeven
    </h3>
    <p>
      Votre Manager Cleeven valide ensuite auprès du client et revient vers vous.
    </p>
    <p>
      Pour les congés d’une durée inférieure à une semaine, merci de formuler votre demande au minimum 15 jours avant la date souhaitée.
    </p>
    <p>
      Pour une durée égale ou supérieure à une semaine, merci de respecter un délai minimum d’un mois.
    </p>
    <p>
      Le cas échéant, la demande de congés ne pourra être acceptée.
    </p>
  </div>
)

export default CongesContent